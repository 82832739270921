<template>
  <div>
    <div class="search-bar">
      <a-form-model ref="searchRef"
                    :model="searchForm"
                    :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 18 }">
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="realname"
                               label="真实姓名">
              <a-input allowClear
                       v-model="searchForm.realname"
                       placeholder="请输入姓名"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="phone"
                               label="手机号">
              <a-input allowClear
                       v-model="searchForm.phone"
                       placeholder="请输入手机号"></a-input>
            </a-form-model-item>
          </a-col>
          
          <a-col :span="8">
            <a-form-model-item label="账号状态"
                               prop="status">
              <a-select v-model="searchForm.status">
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="-1">禁用</a-select-option>
                <a-select-option value="1">正常</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="outlets_city_adcode" label="所属城市">
              <a-select v-model="searchForm.outlets_city_adcode"
                        style="width: 100%" placeholder="所属城市"
                        @change="handlerSearch">
                <a-select-option v-if="!isCityManage" key="" value="">不限</a-select-option>
                <a-select-option v-for="(item, index) in cityList"
                                 :key="index"
                                 :value="item.adcode">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="outlets_id" label="归属网点">
              <a-select show-search allowClear
                        placeholder="请输入网点"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="initOutletsList"
                        v-model="searchForm.outlets_id">
                <a-select-option v-for="(item, index) in outletsList"
                                 :key="index"
                                 :value="item.outlets_id">{{item.outlets_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <div class="flex justify-end mt-1">
              <a-button
                  @click="resetForm">重置</a-button>
              <a-button type="primary" html-type="submit" class="ml-3 mr-1"
                  @click="handlerSearch">搜索</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <base-table ref="tableRef"
                :columnsData="columns"
                rowKey="id"
                :tableData="tableData"
                :total="searchForm.total"
                :getListFunc="initData"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">配送员</h4>
          <a-button class="ml-3"
                    type="primary"
                    @click="handlerEdit('add')"
                    html-type="submit">新增</a-button>
        </div>
      </template>

      <template #status="{text}">
        <a-tag style="margin-right:0;" :color="text===1? 'green' : 'red' ">{{text==1? '正常' : '停用'}}</a-tag>
      </template>
      <template #operation="{record}">
        <a-button type="link" class="p-0 mr-3"
                  @click="handlerEdit('edit',record)">编辑</a-button>
        <a-button type="link" class="p-0"
                  @click="handlerUpdateStatus(record)">{{record.status === 1 ? '停用' : '启用'}}</a-button>
      </template>
    </base-table>

    <add-modal v-if="isEidtShow"
               :show.sync="isEidtShow"
               :id="activeId"
               :type="type"
               :scope="detailModalType"/>
  </div>
</template>

<script>
import { getOutletsList } from '@/api/customer'
import {
  getCourierList,
  updateCourierStatus
} from "@/api/system/courier.js"
import { 
  getMarketCityList,
  isCityManage,
} from '@/utils/authData.js'

import addModal from '@/components/account/courier-modal.vue'

export default {
  components: { addModal },
  data () {
    return {
      isCityManage: isCityManage(),
      detailModalType: isCityManage() ? "city" : "all",

      cityList: [],
      outletsList: [],
      searchForm: {
        realname: "",
        phone: "",
        status: '1',
        outlets_id: undefined,
        outlets_city_adcode: undefined,
        total: 0,
        page: 1,
        page_count: 20,
      },
      columns: [
        {
          title: "账号ID",
          dataIndex: "id",
          align: "center",
          width: "9%",
        },
        {
          title: "编号",
          dataIndex: "identity_card",
          align: "center",
        },
        {
          title: "显示名",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "真实姓名",
          dataIndex: "realname",
          align: "center",
        },
        {
          title: "手机号",
          dataIndex: "phone",
          align: "center",
        },
        {
          title: "城市市场",
          dataIndex: "outlets_city_name",
          align: "center",
          width: "10%",
        },
        {
          title: "归属网点",
          dataIndex: "outlets_name",
          align: "center",
        },
        {
          title: "状态",
          dataIndex: "status",
          align: "center",
          slots: { customRender: 'status' },
          width: 80,
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: { customRender: "operation" },
          align: "center",
          width: 130,
        },
      ],

      type: "add",
      activeId: '',
      tableData: [],
      accountList: [],
      roleList: [],
      isEidtShow: false,
    }
  },
  async mounted () {
    this.cityList = await getMarketCityList()
    if(this.isCityManage){
      if(this.cityList.length == 1){
        this.searchForm.outlets_city_adcode = this.cityList[0].adcode
        this.handlerSearch()
      }
    }else{
      this.handlerSearch()
    }
  },
  methods: {
    async initData () {
      if(this.isCityManage){
        if(!this.searchForm.outlets_city_adcode){
          this.$message.info("请选择所属城市")
          return
        }
      }

      const { data, code } = await getCourierList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.searchForm.total = data.total_count
      }
    },

    async initOutletsList (value) {
      const params = {
        outlets_name: value,
      }
      if (value) {
        const { data, code } = await getOutletsList(params)
        if (code === 0) this.outletsList = data.list
      }
    },

    handlerSearch(){
      this.searchForm.page = 1
      this.initData()
    },

    handlerEdit (type, row) {
      this.type = type
      this.activeId = row ? row.id : ''
      this.isEidtShow = true
    },

    handlerUpdateStatus (row) {
      const _this = this
       this.$confirm({
        title: "温馨提示",
        content: `是否确认${row.status === 1 ? '停用': '恢复'}该账号？`,
        okText: "确认",
        cancelText: "取消",
        onOk() {
          updateCourierStatus({ id: row.id, status: row.status === 1? -1: 1 }).then(
            (res) => {
              if (res.code === 0) {
                _this.$message.success("操作成功！")
                _this.initData()
              }
            }
          )
        },
      })
    },

    resetForm () {
      this.$refs.searchRef.resetFields()
      this.initData()
    },
  },
}
</script>

<style>
</style>